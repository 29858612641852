html {
  min-height: 100%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: black !important; */
  background-image: url('assets/bi.png') !important;
  color: white;
  min-height: 100vh;
  display: flex;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
}

p{
  margin-top: 0 !important;
}

#bpinstruct{
  margin-top: 8px !important;
}

.formlabel{
  margin-bottom: 2px !important;
}

.customPhoneBtn{
  max-width:195px;
  background: transparent;
}

.customPhoneBtn input{
  background: transparent;
  color: white;
}
